import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ThemeContextProvider } from "./context/ThemeContext";
import { BrowserContextProvider } from "./context/BrowserContext";

// import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/styles.css";
import "bootstrap/dist/js/bootstrap.js";
import "./assets/css/tiny-slider.css";

ReactDOM.render(
  <ThemeContextProvider>
    <BrowserContextProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </BrowserContextProvider>
  </ThemeContextProvider>,
  document.getElementById("root")
);
