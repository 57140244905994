import { useState } from "react";
import { createContext } from "react";

export const BrowserContext = createContext();

export const BrowserContextProvider = (props) => {
  const [pathName, setPathName] = useState("");

  return (
    <BrowserContext.Provider value={[pathName, setPathName]}>
      {props.children}
    </BrowserContext.Provider>
  );
};
