import { useState, useEffect, createContext } from "react";

export const ThemeContext = createContext();

export const ThemeContextProvider = (props) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const dark_mode = JSON.parse(localStorage.getItem("dark-mode"));
    setIsDarkMode(dark_mode);
  }, []);

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark-mode");
      localStorage.setItem("dark-mode", true);
    } else {
      document.body.classList.remove("dark-mode");
      localStorage.setItem("dark-mode", false);
    }
  }, [isDarkMode]);

  return (
    <ThemeContext.Provider value={[isDarkMode, setIsDarkMode]}>
      {props.children}
    </ThemeContext.Provider>
  );
};
