import { Route, Switch, withRouter } from "react-router-dom";
import { lazy, Suspense } from "react";
// import Routes from "./routes/Routes";

const Routes = lazy(() =>
  import(/* webpackChunkName: "routes" */ "./routes/Routes")
);

const WaitingComponent = (RefComponent) => {
  return (props) => (
    <Suspense fallback={<div>Loading...</div>}>
      <RefComponent {...props} />
    </Suspense>
  );
};

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/" component={WaitingComponent(Routes)} />
      </Switch>
    </div>
  );
}

export default withRouter(App);
